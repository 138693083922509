import { Component } from '@angular/core';

@Component({
  selector: 'ngx-verify-candidate',
  templateUrl: './verify-candidate.component.html',
  styleUrls: ['./verify-candidate.component.scss'],
})
export class VerifyCandidateComponent {

}
