import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss'],
})
export class DeleteConfirmDialogComponent {
  @Input() title = 'Are you sure?';

  @Input() message = 'This action cannot be undone. Do you want to proceed?';

  SliceLimit = 15;

  maxLength = 30;

  constructor(
    private fb: FormBuilder,
    protected dialogRef: NbDialogRef<DeleteConfirmDialogComponent>,
  ) { }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
