/* eslint-disable no-useless-constructor */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LayoutService } from '../../../@core/utils';
import { AuthService } from '../../../auth/service/auth.service';
import { ChangePasswordDialogComponent } from '../../../pages/training-provider/dialogs/change-password-dialog/change-password-dialog.component';
import { SecurityService } from '../../../services/exam/security.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export default class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  userPictureOnly = false;

  user;

  userRole: string;

  env = environment;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  convertedPath = '';


  userMenu = [
    { title: 'User profile', link: '/auth/user-profile' },
    { title: 'Change password', action: 'changePassword' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private dialogService: NbDialogService,
    public securityService: SecurityService,
  ) {
    this.user = this.authService.getUser();
    this.authService.getUserProfile().subscribe(
      (profileData) => {
        if (this.user.user.userId === profileData.data.userId && profileData.data.profilePicPath) {
          this.user.user.profilePicture = profileData.data.profilePicPath;
          this.authService.profilePicPath = profileData.data.profilePicPath;
          localStorage.setItem('userData', JSON.stringify(this.user));
          this.user.user.profilePicture = this.authService.profilePicPath;
          if (this.user && this.user.user && this.user.user.profilePicture) {
            this.convertedPath = this.user.user.profilePicture.replace(/\\/g, '/');
          }
          this.user.user.profilePicture = this.user.user.profilePicture ? `${this.env.apiUrl}/${this.convertedPath}` : '../../../../assets/images/profilqe.avif';
        }
      },
      (error) => {
        console.error('Error fetching user profile:', error);
      },
    );
  }

  ngOnInit() {

    // hiding the profile button if the user role is super user for now
    const userdata = localStorage.getItem('userData');
    const userObject = JSON.parse(userdata);
    this.userRole = userObject.userRole;



    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.userPictureOnly = isLessThanXl;
      });

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
      });
    this.menuService.onItemClick()
      .pipe(filter(({ tag }) => tag === 'user-context-menu'), map(({ item: { title } }) => {
        if (title === 'Change password') this.openChangePassword();
      }),
      ) // Needed the below console for test purposes
      .subscribe(title => console.log(title));
  }

  openChangePassword() {
    this.dialogService.open(ChangePasswordDialogComponent, {
      context: {
        // You can pass any data to the dialog if needed
      },
    });
  }

  shouldDisplayAction(): boolean {
    return this.userRole !== 'SuperUser';
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.authService.logOut();
  }
}
