<!-- eslint-disable max-len -->
<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Training Pegasus</a>
  </div>
</div>

<div class="header-container">
  <nb-select
  [selected]="currentTheme"
  (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes"
    [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <nb-actions size="small">

    <nb-action class="control-item">
      <!-- <nb-search type="rotate-layout"></nb-search> -->
    </nb-action>
    <!-- *ngIf="securityService.examCompleted || (securityService.examCompleted !== false && userRole === 'Trainee')" -->
    <nb-action *ngIf="securityService.getExamCompleted() || (securityService.getExamCompleted() && userRole === 'Trainee')" class="control-item" icon="log-out-outline"
    (click)="logout()" title="Logout"></nb-action>
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" title="Profile" *ngIf="shouldDisplayAction()">
      <nb-user [nbContextMenu]="userMenu"
               [name]="(user?.user.firstName || '') + ' ' + (user?.user.lastName || '')"
               [picture]="user?.user?.profilePicture"
               nbContextMenuTag="user-context-menu">
      </nb-user>
    </nb-action>

  </nb-actions>
</div>
