import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordService } from '../../../../services/training-provider/change-password.service';
import { capitalCaseValidator, numberValidator, passwordMatchValidator, smallCaseValidator, specialCharacterValidator } from '../../../../modules/functions/validators';

@Component({
  selector: 'ngx-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
  myForm: FormGroup;

  title: string | SafeHtml;

  showOldPassword = false;

  showNewPassword = false;

  showConfirmPassword = false;

  constructor(
    protected dialogRef: NbDialogRef<ChangePasswordDialogComponent>,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private toastrService: NbToastrService,
    private translate: TranslateService,
    private changePasswordService: ChangePasswordService,
  ) {
    this.title = sanitizer.bypassSecurityTrustHtml('Change Password');
    this.initForm();
  }

  initForm() {
    this.myForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.maxLength(20)]],
      newPassword: ['', [Validators.required,
        Validators.minLength(8),
        capitalCaseValidator,
        smallCaseValidator,
        specialCharacterValidator,
        numberValidator,
        Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, passwordMatchValidator('newPassword', 'confirmPassword')]],
    }, { validator: passwordMatchValidator('newPassword', 'confirmPassword') });
  }

  onSubmit() {
    const form = {
      oldPassword: this.myForm.get('oldPassword').value,
      newPassword: this.myForm.get('newPassword').value,
      confirmPassword: this.myForm.get('confirmPassword').value,
    };
    this.changePasswordService.updatePassword(form).subscribe(
      (response) => {
        if ((response.status as string).toLowerCase() === 'success') {
          this.toastrService.success(this.translate.instant('change_password.success.update'),
            this.translate.instant('change_password.success.title'));
          this.dialogRef.close();
        }
      },
      (error) => {
        error.error.errors.forEach(err => {
          err.errorCodes.forEach(eror => {
            if (eror.errorCode === 'INVALID_OLD_PASSWORD') {
              this.toastrService.danger(
                this.translate.instant('change_password.errors.old_password'),
                this.translate.instant('change_password.errors.title'));
            } else {
              this.toastrService.danger(
                this.translate.instant('change_password.errors.error'),
                this.translate.instant('change_password.errors.title'));
            }
          });
        });
      },
    );
  }

  resetForm() {
    this.myForm.reset();
  }

  getFormControlStatus(controlName: string): string {
    const control = this.myForm.get(controlName);
    if ((control.dirty || control.touched) && control.invalid) {
      return 'danger';
    } if (control.dirty || control.touched) {
      return 'success';
    }
    return 'basic';
  }

  isInvalid(controlName: string): boolean {
    const control = this.myForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }


  hasError(controlName: string, errorType: string): boolean {
    const control = this.myForm.get(controlName);
    return control.hasError(errorType);
  }

  togglePasswordVisibility(field: string) {
    if (field === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
