import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { MenuService } from './services/menu/menu.service';
import { AuthService } from './auth/service/auth.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export default class AppComponent implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private menuService: MenuService,
    private authService: AuthService,
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }

}
