import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(value, args?) {
    if (!value) return null;
    if (!args) return value;

    // eslint-disable-next-line no-param-reassign
    args = args.toLowerCase();
    return value.filter((item) => JSON.stringify(item)
      .toLowerCase()
      .includes(args));
  }
}
