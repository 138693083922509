import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormsModule as ngFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NbInputModule, NbCardModule, NbButtonModule, NbActionsModule, NbUserModule, NbCheckboxModule, NbRadioModule, NbDatepickerModule, NbSelectModule, NbIconModule, NbLayoutModule, NbDialogModule } from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import ThemeModule from '../../@theme/theme.module';
import { SharedModule } from '../shared/shared.module';
import { EmployeesRoutingModule } from '../../pages/employees/employees-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    ngFormsModule,
    NbLayoutModule,
    NgxDatatableModule,
    SharedModule,
  ],
  exports:[
    CommonModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    ngFormsModule,
    SharedModule,
    NbLayoutModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbLayoutModule,
    NgxDatatableModule,
    NbDialogModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    EmployeesRoutingModule,
    ngFormsModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    ThemeModule,
    NbLayoutModule,
    NgxDatatableModule,
  ],
})
export class NebularModule {}
