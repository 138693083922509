import { Component } from '@angular/core';

@Component({
  selector: 'ngx-attendance-details',
  templateUrl: './attendance-details.component.html',
  styleUrls: ['./attendance-details.component.scss'],
})
export class AttendanceDetailsComponent {

}
