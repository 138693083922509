<!-- eslint-disable max-len -->
<div style="display: flex; justify-content: center;">
  <img src="../../../../assets/images/login_logo.png"
    alt="TRAINING PEGASUS LOGO" width="auto" height="150px">
</div>
<h1 id="title" class="title" style="margin-top: 0.9rem;">
  {{ 'candidate_login.login_title' | translate }}
</h1>
<form (ngSubmit)="login(loginForm.value)" #loginForm="ngForm" aria-labelledby="title">
  <div class="form-group">
    <label for="emailAddress" class="label">
      {{ 'candidate_login.email_address' | translate }}
    </label>
    <input type="email" nbInput fullWidth [(ngModel)]="user.email" id="emailAddress" name="email" placeholder="Email"
      #email="ngModel" required email />
    <div *ngIf="email.invalid && (email.dirty || email.touched)">
      <div *ngIf="email.errors.required" class="error-message">
        {{ 'candidate_login.email_required' | translate }}
      </div>
      <div *ngIf="email.errors.email" class="error-message">
        {{ 'candidate_login.invalid_email' | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="password" class="label">
      {{ 'candidate_login.password' | translate }}
    </label>
    <div class="password-input-container">
      <input [type]="showPassword ? 'text' : 'password'" 
             nbInput 
             fullWidth 
             [(ngModel)]="user.password" 
             id="password" 
             name="password"
             placeholder="Password" 
             #password="ngModel" 
             required 
             minlength="5" />
      <button nbSuffix nbButton ghost (click)="toggleShowPassword()" type="button">
        <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                 pack="eva"
                 [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
        </nb-icon>
      </button>
    </div>
    
    <div *ngIf="password.invalid && (password.dirty || password.touched)">
      <div *ngIf="password.errors.required" class="error-message">
        {{ 'candidate_login.password_required' | translate }}.
      </div>
      <div *ngIf="password.errors.minlength" class="error-message">
        {{ 'candidate_login.password_length_error' | translate }}.
      </div>
    </div>
  </div>
  <div class="form-group">
    <nb-checkbox [(ngModel)]="user.rememberMe" [ngModelOptions]="{ standalone: true }">
      {{ 'candidate_login.remember_me' | translate }}
    </nb-checkbox>
  </div>
  <button type="submit" [disabled]="loginForm.invalid" nbButton fullWidth status="success">
    {{ 'candidate_login.submit' | translate }}
  </button>
</form>
<nav class="navbar navbar-expand-lg navbar-light d-flex justify-content-center">
  <a class="navbar-brand"><b>© Copyright 2015 Osmosys. All rights reserved 1.0.0.73</b></a>
</nav>
