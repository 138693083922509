<!-- eslint-disable max-len -->
<div class="col-lg">
  <nb-card>
    <nb-card-header>
      <div class="float-left py-2">
        {{ 'employee.print_question.title' | translate }}
      </div>
      <button
        nbButton
        hero
        class="float-right"
        status="primary"
        (click)="createQuestionPaper()"
      >
        {{ 'employee.print_question.add_question_paper' | translate }}
      </button>
    </nb-card-header>
    <nb-card-body>
      <div class="row px-4 pt-3">
        <div class="col-lg-12 filters">
          <div class="service-container spread-out">
            <div class="fill-available mr-3">
              <label for="selectedItem" class="label">
                {{ 'employee.print_question.service' | translate }}
              </label>
              <nb-select
                [(selected)]="selectedItem"
                class="w-100"
                fullWidth="true"
              >
                <nb-option
                  [value]="service.serviceId"
                  *ngFor="let service of ServiceList"
                >
                  {{ service.serviceName }}
                </nb-option>
              </nb-select>
            </div>
          </div>

          <div class="service-container spread-out">
            <div class="get-subsec-btn">
              <div>
                <button
                  nbButton
                  outline
                  status="primary"
                  (click)="getQuestionPapers(selectedItem)"
                >
                  {{ 'employee.print_question.get_questions' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter">
        <input
          nbInput
          type="text"
          placeholder="Search"
          (keyup)="onSearchChange()" 
          [(ngModel)]="searchTerm"
          />
          <!-- (blur)="onSearchChange()" -->
        <nb-select [(ngModel)]="dataCount" name="dataCount"   (ngModelChange)="onDataCountChange($event)">
          <nb-option [value]="5">5</nb-option>
          <nb-option [value]="10">10</nb-option>
          <nb-option [value]="20">20</nb-option>
          <nb-option [value]="50">50</nb-option>
          <nb-option [value]="100">100</nb-option>
        </nb-select>
      </div>

      <ngx-datatable
        class="material custom-datatable"
        [rows]="rows"
        columnMode="force"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarV]="false"
        [scrollbarH]="false"
        [loadingIndicator]="dataLoading"
        [sorts]="[{ prop: 'createdOn', dir: 'desc' }]"
        rowHeight="auto"
        [count]="totalRecords"
        [externalPaging]="true"
        [offset]="currentPage"
        [limit]="maxRows"
        (page)='onPageChange($event)'
        (sort)="onSort($event)"
      >
        <ngx-datatable-column
          name="Question paper"
          prop="questionPaperName"
          [width]="200"
        >
          <ng-template
            let-value="value"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            {{ value ? value : rows[rowIndex].questionPaperId }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Service"
          [width]="300"
          prop="serviceName"
        ></ngx-datatable-column>

        <ngx-datatable-column
          name="Created by"
          prop="createdByUserName"
          [width]="200"
        >
        </ngx-datatable-column>

        <ngx-datatable-column name="Created on" prop="createdOn" [width]="170">
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" prop="isPublished" [width]="150">
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions" [width]="150" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <strong>{{ column.name }}</strong></ng-template
          >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button
              *ngIf="(row.isPublished === 'Unpublished') || (row.associated === 0)"
              class="btn btn-sm btn-primary mr-1"
            >
              <nb-icon icon="edit" (click)="goToEditPaper(row.id)"> </nb-icon>
            </button>
            <button
              *ngIf="row.isPublished !== 'Unpublished'"
              class="btn btn-sm btn-primary mr-1"
            >
              <nb-icon icon="eye" (click)="goToEditPaper(row.id)"> </nb-icon>
            </button>
            <button
              class="btn btn-sm btn-primary mr-1"
            >
              <nb-icon icon="download" (click)="DownloadQuesPaper(row,positions.TOP_RIGHT,'danger')"> </nb-icon>
            </button>
            <button
            class="btn btn-sm"
            [ngClass]="{'btn-danger': (row.isPublished === 'Unpublished') || (row.associatedExamsCount === 0), 'btn-secondary': !(row.isPublished === 'Unpublished') && !(row.associatedExamsCount === 0)}"
            [disabled]="!((row.isPublished === 'Unpublished') || (row.associatedExamsCount === 0))"
            (click)="deleteQuesPaper(positions.TOP_RIGHT, 'danger', row)"
            >
              <nb-icon icon="trash"></nb-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
          >
            <div style="padding: 5px 10px; min-width: 250px;">
              <div class="rowFooter">
                Showing {{ rowCount !== 0 ? ((curPage - 1) * pageSize) + 1 : 0 }} to {{ (curPage * pageSize) <= rowCount ? (curPage * pageSize) : rowCount }} of {{ rowCount }} records
              </div>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!((rowCount / pageSize) > 1)"
              (change)="table.onFooterPage($event)">
          </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </nb-card-body>
  </nb-card>
</div>
