import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/service/auth.service';

export interface ChangePasswordResponse {
  status: string,
  data?: number | string,
  message?: null | string,
  stackTrace?: null | string,
  errors?: HttpErrorResponse
}

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {

  constructor(private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  userObject = this.authService.getUser();

  updatePassword(
    form: {oldPassword: string, newPassword: string, confirmPassword: string}): Observable<ChangePasswordResponse> {
    const { userId } = this.userObject.user;
    const headers = new HttpHeaders()
      .set('userid', userId)
      .set('Authorization', `Bearer ${this.userObject.token}`)
      .set('Content-Type', 'application/json');


    // Set up options with headers
    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${this.userObject.user.trainingProviderId}/ChangePassword`;
    return this.httpClient.put(API_URL, form, options).pipe(
      map((data: ChangePasswordResponse) => data),
    );
  }
}
