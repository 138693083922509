import { Component } from '@angular/core';

@Component({
  selector: 'ngx-marks',
  templateUrl: './marks.component.html',
  styleUrls: ['./marks.component.scss'],
})
export class MarksComponent {

}
