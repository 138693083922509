import { FormGroup } from '@angular/forms';

export class TableFilter {

  updateFilter(event, oldData) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const filterData = oldData.filter((d) => Object.values(d).some((prop) =>
      prop?.toString().toLowerCase().includes(val),
    ));

    return filterData;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  trimFormValues(form: FormGroup): FormGroup<unknown> {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
    return form;
  }
}
